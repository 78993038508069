import Dexie from 'dexie';

/**
 * Represents the structure of the database.
 */
export interface DbData extends Dexie {}

let DB: null | Dexie;

/**
 * Initializes the Dexie database.
 * @returns The initialized Dexie database.
 */
const initDB = (): DbData => {
  const db = new Dexie('SMR');

  db.version(1).stores({
    holidays: '++id, date,observedDate, nameFr, nameEn, description, province, federal',
  });

  return db;
};

/**
 * Gets the initialized Dexie database instance.
 * If the instance does not exist, it initializes and returns it.
 * @returns The Dexie database instance.
 */
const getDB = (): DbData => {
  if (!DB) {
    DB = initDB();
  }

  const idb = DB.backendDB();

  try {
    // Check if connection to idb did not close in the meantime
    idb.transaction('tasks').abort();
  }
  catch (error) {
    // Reinitialize DB if connection is closed
    DB = initDB();
  }

  return DB;
};

export default getDB;
