import { symRoundedAutorenew, symRoundedCheck, symRoundedError, symRoundedWarning } from '@quasar/extras/material-symbols-rounded';
import type { QNotifyUpdateOptions } from 'quasar';
import { Loading, Notify } from 'quasar';
import { Positions } from '../types/Positions.interface';

export enum NotificationType {
  Success = 'green-5',
  Loading = 'accent',
  Warning = 'warning',
  Error = 'negative'
}

interface NotificationOptions extends QNotifyUpdateOptions {
  position?: Positions;
  timeout?: number;
}

export function showNotification(type: NotificationType, message: string, options: NotificationOptions | null = null): void {
  Loading.hide();

  if (!options) {
    options = {};
  }
  const { position = Positions.Top, timeout = 3000,  ...rest } = options as NotificationOptions;

  Notify.create({
    color: type,
    ...(type === NotificationType.Success && { textColor: 'white', icon: symRoundedCheck }),
    ...(type === NotificationType.Loading && { textColor: 'white', icon: symRoundedAutorenew }),
    ...(type === NotificationType.Warning && { textColor: 'black', icon: symRoundedWarning }),
    ...(type === NotificationType.Error && { textColor: 'white', icon: symRoundedError }),
    position,
    message,
    timeout,
    ...rest
  });
}

export function showSuccessNotification(message: string, options: NotificationOptions | null = null): void {
  showNotification(NotificationType.Success, message, options);
}

export function showLoadingNotification(message: string, options: NotificationOptions | null = null): void {
  showNotification(NotificationType.Loading, message, options);
}

export function showWarningNotification(message: string, options: NotificationOptions | null = null): void {
  showNotification(NotificationType.Warning, message, options);
}

export function showErrorNotification(message: string, options: NotificationOptions | null = null): void {
  showNotification(NotificationType.Error, message, options);
}
