import { useGlobalStore } from 'src/stores/useGlobalStore';
import { useUserStore } from 'src/stores/useUserStore';
import { type RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    component: () => import('src/pages/Guests/GuestLayout.vue'),
    beforeEnter: async (to, from, next) => {
      const userStore = useUserStore();
      const globalStore = useGlobalStore();

      if (userStore.loggedIn && userStore.token) {
        next('/');
      }
      else {
        await globalStore.resetApp();
        next();
      }
    },
    children: [
      {
        path: '/login',
        component: () => import('src/pages/Guests/LoginPage.vue')
      },
      {
        path: '/password-reset',
        component: () => import('src/pages/Guests/PasswordResetPage.vue')
      },
      {
        path: '/forgot',
        component: () => import('src/pages/Guests/ForgotPage.vue')
      }
    ]
  }
];

export default routes;
