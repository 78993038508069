import { defineStore } from 'pinia'
import { Dialog } from 'quasar'
import getDB from 'src/functions/db'
import { showSuccessNotification } from 'src/functions/function-show-notifications'

import { useUserStore } from 'src/stores/useUserStore'
import { axiosApi } from 'src/utils/axios'
import { ref } from 'vue'
export interface UpdateState {
  updateAvailable: boolean
  restartRequired: boolean
}

export interface CheckForUpdatesOptions {
  notification?: boolean
  forced?: boolean
}

export const useUpdateStore = defineStore('Update', () => {
  const state = ref({
    updateAvailable: false,
    restartRequired: true,
  } as UpdateState)

  const lastPatchNoteVersion = ref<string | null>(null)

  /**
   * Checks for updates.
   * @param options - Options for checking updates.
   */
  const checkForUpdates = async (options: CheckForUpdatesOptions = {}) => {
    try {
      if ('serviceWorker' in navigator) {
        if (options.notification) {
          showSuccessNotification('Vérification des mises à jour...')
        }

        const registrations = await navigator.serviceWorker.getRegistrations()
        for (const registration of registrations) {
          await registration.update()
        }

        if (options.forced === true) {
          //wait 5 seconds before showing the update available and restart required
          setTimeout(() => {
            state.value.updateAvailable = true
            state.value.restartRequired = true
          }, 5000)
        }
      }
    } catch (error) {
      console.warn("Can't update service worker:", error)
    }
  }

  /**
   * Deletes all databases.
   */
  const deleteAllDatabases = async (): Promise<void> => {
    try {
      const databases = await indexedDB.databases()
      databases.forEach((db) => {
        if (db.name) {
          indexedDB.deleteDatabase(db.name)
        }
      })
    } catch (error) {
      console.error('Error fetching databases: ', error)
    }
  }

  /**
   * Uninstalls the service worker.
   * @param reset - Whether to reset the application or not.
   */
  const uninstall = async (reset = true) => {
    const UserStore = useUserStore()

    // Clear all cache storage
    if ('caches' in window) {
      try {
        const cacheNames = await caches.keys()
        await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)))
      } catch (error) {
        console.warn("Can't clear caches:", error)
      }
    }
    // Unregister service workers
    if ('serviceWorker' in navigator) {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations()
        await Promise.all(registrations.map((registration) => registration.unregister()))
      } catch (error) {
        console.warn("Can't unregister service workers:", error)
      }
    }

    if (reset) {
      Dialog.create({
        title: 'Réinitialisation',
        message: "Tous les paramètres de l'application seront réinitialisés, vous devrez vous reconnecter.",
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        await getDB().delete()
        //
        window.localStorage.clear()
        window.sessionStorage.clear()

        setTimeout(() => {
          clearLocalStorage()
        }, 1000)
        window.location.reload()
      })
    }
  }

  async function clearLocalStorage() {
    try {
      // Method 1: Standard clear
      window.localStorage.clear()

      // Method 2: Manual key removal
      for (const key in window.localStorage) {
        if (window.localStorage.hasOwnProperty(key)) {
          window.localStorage.removeItem(key)
        }
      }

      // Method 3: Clear using length property
      while (window.localStorage.length) {
        const key = window.localStorage.key(0)
        window.localStorage.removeItem(key)
      }

      // Verify clearing worked
      if (window.localStorage.length === 0) {
        return true
      } else {
        throw new Error('Failed to clear localStorage')
      }
    } catch (error) {
      console.error('Error clearing localStorage:', error)

      // Alternative approach using timing
      try {
        const now = new Date().getTime()
        const itemsToRemove = []

        // Collect all keys
        for (let i = 0; i < window.localStorage.length; i++) {
          const key = window.localStorage.key(i)
          itemsToRemove.push(key)
        }

        // Remove items one by one
        itemsToRemove.forEach((key) => {
          window.localStorage.removeItem(key)
        })

        return window.localStorage.length === 0
      } catch (fallbackError) {
        console.error('Fallback clearing failed:', fallbackError)
        return false
      }
    }
  }

  /* Push notifications */

  async function subscribe(sub: PushSubscription) {
    try {
      const key = sub.getKey('p256dh')
      const token = sub.getKey('auth')

      if (!key || !token) {
        throw new Error('Required subscription keys are missing')
      }

      // Convert the keys to base64 strings
      const public_key = btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
      const auth_token = btoa(String.fromCharCode.apply(null, new Uint8Array(token)))

      const data = {
        endpoint: sub.endpoint,
        public_key,
        auth_token,
        encoding: 'aes128gcm', // Modern browsers use this encoding
      }

      const response = await axiosApi.post('/notifications/subscribe', data)

      return response
    } catch (error) {
      console.error('Failed to send subscription to server:', error)
      throw error
    }
  }

  async function enablePushNotifications() {
    try {
      // Check if the browser supports notifications
      if (!('Notification' in window)) {
        console.error('This browser does not support notifications')
        throw new Error('This browser does not support notifications')
      }

      // Check if service workers are supported
      if (!('serviceWorker' in navigator)) {
        console.error('Service workers are not supported')
        throw new Error('Service workers are not supported')
      }

      // Check if Push messaging is supported
      if (!('PushManager' in window)) {
        console.error('Push messaging is not supported')
        throw new Error('Push messaging is not supported')
      }

      // Request notification permission if needed

      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission()

        if (permission !== 'granted') {
          throw new Error('Notification permission denied')
        }
      }

      // Get service worker registration

      let registration
      try {
        // First try to get existing registrations
        const registrations = await navigator.serviceWorker.getRegistrations()

        if (registrations.length > 0) {
          registration = registrations[0]
        } else {
          // If no registration exists, register the service worker

          registration = await navigator.serviceWorker.register('/sw.js', {
            scope: '/',
          })
        }

        // Wait for the service worker to be ready
        if (registration.active) {
        } else {
          await new Promise<void>((resolve) => {
            registration.addEventListener('activate', () => {
              resolve()
            })
          })
        }
      } catch (error) {
        console.error('Failed to get/register service worker:', error)
        throw error
      }

      // Check existing subscription

      let subscription = await registration.pushManager.getSubscription()

      if (!subscription) {
        const vapidPublicKey = import.meta.env.VITE_VAPID_PUBLIC_KEY
        if (!vapidPublicKey) {
          throw new Error('VAPID public key not found in environment variables')
        }

        try {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey,
          })
        } catch (subscribeError) {
          console.error('Failed to create push subscription:', subscribeError)
          throw subscribeError
        }
      }

      // Send subscription to server

      await subscribe(subscription)

      return true
    } catch (error) {
      console.error('Failed to enable push notifications:', error)
      return false
    }
  }

  function disablePushNotifications() {
    navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager.getSubscription().then((subscription) => {
        if (!subscription) {
          return
        }
        subscription.unsubscribe().then(() => {
          const endpoint = subscription.endpoint
          axiosApi
            .post('/notifications/unsubscribe', { endpoint })
            .then((response) => {})
            .catch((error) => {
              console.error('Error:', error)
            })
        })
      })
    })
  }
  /**
   * Installs the update.
   */
  const installUpdate = async () => {
    try {
      await checkForUpdates({ notification: false })
      window.location.reload()
    } catch (error) {
      console.warn("Can't install update:", error)
    }
  }

  return {
    state,
    enablePushNotifications,
    disablePushNotifications,
    checkForUpdates,
    installUpdate,
    uninstall,
  }
})
