import { type RouteRecordRaw } from 'vue-router';

export const punchRoutes: RouteRecordRaw[] = [
  {
    path: '/punch',
    component: () => import('src/pages/Punch/PunchHome.vue')
  }
];

export default punchRoutes;
