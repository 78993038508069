import { punchRoutes } from './punch_routes'

import { useUserStore } from 'src/stores/useUserStore'
import { type RouteRecordRaw } from 'vue-router'
import authRoutes from './auth_routes'
import crudRoutes from './crud_routes'
const routes: RouteRecordRaw[] = [
  ...authRoutes,
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    beforeEnter: (to, from, next) => {
      const userStore = useUserStore()
      if (!userStore.loggedIn || !userStore.token) {
        next('/login')
      } else {
        next()
      }
    },
    children: [
      { path: '/', component: () => import('pages/IndexPage.vue'), name: 'home' },
      { path: '/settings', component: () => import('pages/SettingsPage.vue'), name: 'settings' },
      { path: '/crudmanager', component: () => import('src/crudmanager/CrudManager.vue') },
      { path: 'policy', component: () => import('pages/PolicyPage.vue') },
      { path: '/parts/clean', component: () => import('pages/PartsCleanPage.vue') },
      { path: '/parts/temporary', component: () => import('pages/TemporaryPartsPage.vue') },
      ...crudRoutes,
      ...punchRoutes,
    ],
  },

  /*
   * Always leave this as last one,
   * but you can also remove it
   */
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
]

export default routes
