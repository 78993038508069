/*
 * import { useGlobalStore } from 'src/stores/useGlobalStore';
 * import { useUserStore } from 'src/stores/useUserStore';
 */

import { type RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/manage/:modelSlug?/:moduleSlug?/:modelId?',
    name: 'crudmanager',
    component: () => import('../crudmanager/CrudManagerFrame.vue'),
  },
]

export default routes
