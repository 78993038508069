import { defineStore } from 'pinia'
import { Loading } from 'quasar'
import { showErrorNotification, showSuccessNotification } from 'src/functions/function-show-notifications'
import { Router } from 'src/router/index'
import { axiosApi } from 'src/utils/axios'
import { computed, ref } from 'vue'
import { useAppSettingsStore } from './useAppSettingsStore'
import { useGlobalStore } from './useGlobalStore'

interface ModelStatus {
  id: string
  model_type: string
  model_id: string
  active_status: string
  created_at: string
  updated_at: string
}

interface UserInfo {
  id: string
  user_id: string
  first_name: string
  last_name: string
  created_at: string
  updated_at: string
}

interface UserDetails {
  id: string
  username: string
  email: string
  email_verified_at: string | null
  created_at: string
  updated_at: string
  first_name: string
  last_name: string
  is_active: boolean
  deleted_at: string | null
  full_name: string
  model_status: ModelStatus
  infos: UserInfo
  roles: string[]
  permissions: string[]
}

export const useUserStore = defineStore(
  'user-store',
  () => {
    const loggedIn = computed(() => (user.value && user.value.id && token.value ? true : false))
    const user = ref({} as UserDetails | null)
    const token = ref(null as string | null)

    const login = async (email: string, password: string) => {
      Loading.show()
      localStorage.clear()
      const device_name = navigator.userAgent
      try {
        const response = await axiosApi.post('sanctum/token', {
          identity: email,
          password: password,
          device_name: device_name,
        })
        token.value = response.data.token
        user.value = response.data.user
        Router.push('/')
        return loggedIn.value
      } catch (error: any) {
        showErrorNotification(error?.response?.data?.message || 'Login failed')
      } finally {
        regenUserData()
        Loading.hide()
      }

      return loggedIn.value
    }

    const regenUserData = async (payload?: object) => {
      try {
        const appSettings = useAppSettingsStore()
        const response = await axiosApi.get(`user/${appSettings.employeModeId ? `/${appSettings.employeModeId}` : ''}`)
        user.value = response.data
        if (payload) {
          showSuccessNotification('Données utilisateur rechargeés depuis le serveur.')
        }
        const GlobalStore = useGlobalStore()
        GlobalStore.syncAppData()
      } catch (error: any) {
        console.warn(error)
        throw new Error('Impossible de se connecter au serveur')
      }
    }

    const forgot = async (email: string) => {
      try {
        const response = await axiosApi.post('forgot', { identity: email })
        if (response.data.data != 'passwords.throttled') {
          showSuccessNotification(
            'Si le courriel existe dans notre base de données, un courriel de récupération vous a été envoyés, suivez le lien dans ce courriel afin de changer votre mot de passe.',
          )
        } else {
          showErrorNotification('Trop de tentatives, veuillez réessayer plus tard.')
        }
      } catch (error: any) {
        showErrorNotification(error?.response?.data?.message || "Une erreur s'est produite")
      } finally {
        Loading.hide()
        Router.replace('/login')
      }
    }

    const currentUserCan = (permission: string) => {
      return user.value?.permissions?.includes(permission) || false
    }

    const currentUserHasRole = (role: string) => {
      return user.value?.roles?.includes(role) || false
    }

    const reset = () => {
      user.value = null
      token.value = null
      localStorage.clear()

      if (Router.currentRoute.value.path != '/login') {
        Router.replace('/login')
        window.location.reload()
      }
      Loading.hide()
    }

    const logout = async () => {
      Loading.show()
      try {
        await axiosApi.post('logout')
      } catch (error: any) {
        console.warn('Logout failed:', error)
      } finally {
        reset()
      }
    }

    const initUser = () => {
      const GlobalStore = useGlobalStore()
      if (user.value?.id) {
        GlobalStore.syncAppData()
      }
    }

    const fullname = computed(() => {
      return user.value?.full_name || ''
    })

    const isIdentified = computed(() => loggedIn.value && user.value?.id)

    const isDev = computed(() => currentUserHasRole('developer') || process.env.ENVIRONMENT === 'development')

    const getUserId = computed(() => user.value?.id)

    const details = computed({
      get: () => user.value,
      set: (value) => {
        user.value = value
      },
    })

    return {
      details,
      currentUserCan,
      currentUserHasRole,
      getUserId,
      loggedIn,
      user,
      token,
      login,
      regenUserData,
      forgot,
      logout,
      initUser,
      fullname,
      isIdentified,
      isDev,
    }
  },
  { persist: true },
)
