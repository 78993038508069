<template>
  <q-layout view="lHh Lpr lFf">
    <Suspense>
      <router-view />
      <template #fallback>
        <div class="fullscreen flex flex-center">
          <q-spinner-dots size="40" />
        </div>
      </template>
    </Suspense>
  </q-layout>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useQuasar } from 'quasar';
import { useGlobalStore } from 'src/stores/useGlobalStore';
import { useUpdateStore } from 'src/stores/useUpdateStore';
import { onMounted, onUnmounted, watchEffect } from 'vue';

// Initialize stores
const updateStore = useUpdateStore();
const globalStore = useGlobalStore();
const $q = useQuasar();

// Get reactive refs from stores
const { isDarkMode } = storeToRefs(globalStore);

// Watch for dark mode changes and apply them
watchEffect(() => {
  $q.dark.set(isDarkMode.value);
});

// Initialize the application
const initializeApp = async () => {
  try {
    await globalStore.initDarkMode();

    // Check for updates only in production
    if (process.env.NODE_ENV === 'production') {
      await updateStore.checkForUpdates();
    }
  } catch (error) {
    console.error('Failed to initialize app:', error);
    // You might want to show a notification here using Quasar's notify
    $q.notify({
      type: 'negative',
      message: 'Failed to initialize application',
      position: 'top'
    });
  }
};

// Lifecycle hooks
onMounted(() => {
  void initializeApp();
});

onUnmounted(() => {
  // Cleanup if needed
  globalStore.$reset();
  updateStore.$reset();
});
</script>

<style>
/* Add any global styles here */
.fullscreen {
  width: 100vw;
  height: 100vh;
}
</style>
