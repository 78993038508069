import dayJS from 'dayjs'
import { defineStore } from 'pinia'
import { Platform, setCssVar } from 'quasar'
import { computed, ref, watch } from 'vue'
import { useUserStore } from './useUserStore'

interface AppSettings {
  theme: string | null
  debugMode: boolean
  forceMobileMenu: boolean
  showTooltips: boolean
  darkMode: boolean
  secondaryColor: string | null
  darkColor: string | null
  displayMenuLabel: boolean
  displayRightDrawer: boolean
  employeMode: {
    is_active: boolean
    orig_id: string
    employe: number | null
  }
  dateFormat: LabelValue
  gptSettings: {
    FormationListPicker: {
      display: boolean
    }
  }
  editorSettings: {
    tabPosition: 'top' | 'bottom'
    tabDisplay: 'full' | 'icons'
    autoSave: boolean
    confirmBeforeLeaving: boolean
    autoSaveDebounce: number // in seconds
  }
  listerSettings: {
    listMode: 'table' | 'simpleCards' | 'detailedCards'
  }
}
export interface Theme {
  colors: Record<string, string>
}

export interface LabelValue {
  label: string
  value: string
}

export interface UserSetting {
  key: string
  value: string | number | boolean | null
}
export const useAppSettingsStore = defineStore(
  'AppSettings',
  () => {
    // Store state variables
    const theme = ref<Theme | null>(null)
    const debugMode = ref(false)
    const forceMobileMenu = ref(false)
    const showTooltips = ref(true)
    const darkMode = ref(false)
    const secondaryColor = ref<string | null>(null)
    const darkColor = ref<string | null>(null)
    const displayMenuLabel = ref(true)
    const displayRightDrawer = ref(false)
    const employeMode = ref<AppSettings['employeMode']>({ is_active: false, orig_id: '', employe: null })
    const gptSettings = ref<AppSettings['gptSettings']>({ FormationListPicker: { display: true } })

    const editorSettings = ref({
      tabPosition: 'top',
      tabDisplay: 'icons',
      autoSave: true,
      confirmBeforeLeaving: true,
      autoSaveDebounce: 3, // 5 seconds default
    })

    const listerSettings = ref<AppSettings['listerSettings']>({
      listMode: 'simpleCards',
    })

    const userSettings = ref<UserSetting[]>([])
    const flatCards = ref(Platform.is.mobile ? true : false)

    // getUserSettings and setUserSettings allows any part of the application to store its settings user a key identifier

    const dateFormat = ref<AppSettings['dateFormat']>({
      label: 'AAAA-MM-JJ',
      value: 'YYYY-MM-DD',
    })

    const UserStore = useUserStore()
    // Methods
    const setTheme = () => {
      if (theme.value) {
        for (const [key, value] of Object.entries(theme.value.colors)) {
          setCssVar(key, value)
        }
      }
    }

    const switchUser = () => UserStore.regenUserData()

    const employeModeId = computed<number | false>(() => (employeMode.value.is_active && employeMode.value.employe ? employeMode.value.employe : false))

    watch(theme, () => {
      if (theme.value) {
        setTheme()
      }
    })

    const formattedDate = computed((e: string | number | Date = '') => dayJS(e).format(dateFormat.value.value))
    const formattedDateTime = computed((e: string | number | Date = '') => dayJS(e).format(`${dateFormat.value.value} H:m:s`))

    const getUserSettings = (key: string, defaultSetting: string | number | boolean | null = null) => {
      let setting = userSettings.value.find((e) => e.key === key)
      if (!setting) {
        setting = setUserSettings(key, defaultSetting)
      }

      return computed({ get: () => setting.value, set: (value) => setUserSettings(key, value) })
    }

    const setUserSettings = (key: string, value: string | number | boolean | null) => {
      const index = userSettings.value.findIndex((e) => e.key === key)
      if (index > -1) {
        userSettings.value[index].value = value
      } else {
        userSettings.value.push({ key, value })
      }

      return userSettings.value.find((e) => e.key === key)
    }

    return {
      gptSettings,
      formattedDate,
      formattedDateTime,
      dateFormat,
      setTheme,
      switchUser,
      employeModeId,
      debugMode,
      employeMode,
      forceMobileMenu,
      showTooltips,
      darkMode,
      secondaryColor,
      darkColor,
      displayMenuLabel,
      displayRightDrawer,
      theme,
      getUserSettings,
      setUserSettings,
      editorSettings,
      listerSettings,
      flatCards,
    }
  },
  { persist: true },
)
