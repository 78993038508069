// import { Network, ConnectionStatus } from '@capacitor/network';
import type { Dayjs } from 'dayjs'
import dayJS from 'dayjs'
import { defineStore } from 'pinia'
import { Dark, Platform } from 'quasar'
import { useServerSettingsStore } from 'src/stores/useServerSettingsStore'
import { useUserStore } from 'src/stores/useUserStore'
import { axiosApi } from 'src/utils/axios'
import { capitalize, ref } from 'vue'

interface ServerSettings {
  FIRST_DAY_OF_WEEK: number
  DISPLAY_SUIVI_PERCENT: boolean
  ENABLE_CLIENT_INVENTORY: boolean
  ENABLE_CLIENT_EQUIPMENT: boolean
  ENABLE_FORMATIONS: boolean
  ENABLE_DOCUMENTS: boolean
  ENABLE_MEMOS: boolean
  GMAPS_API_KEY: string | null
}

interface SyncAppDataResponse {
  diff: number
  serverSettings?: ServerSettings
}

// Network types
interface ConnectionStatus {
  connected: boolean
  connectionType: string
}

export const useGlobalStore = defineStore(
  'global-store',
  () => {
    let serverTimeDiff = 0
    const virtualDate = ref(null as string | null)
    const connectionStatus = ref(null as ConnectionStatus | null)
    const pageTitle = ref(process.env.APP_NAME)
    let serverSettings: ServerSettings = {
      FIRST_DAY_OF_WEEK: 1,
      DISPLAY_SUIVI_PERCENT: true,
      ENABLE_CLIENT_INVENTORY: true,
      ENABLE_CLIENT_EQUIPMENT: true,
      ENABLE_FORMATIONS: true,
      ENABLE_DOCUMENTS: true,
      ENABLE_MEMOS: true,
      GMAPS_API_KEY: null,
    }

    const isDarkMode = ref(false)

    // Initialize dark mode from localStorage or system preference
    const initDarkMode = () => {
      const savedDarkMode = localStorage.getItem('darkMode')
      if (savedDarkMode !== null) {
        isDarkMode.value = savedDarkMode === 'true'
      } else {
        // Check system preference
        isDarkMode.value = window.matchMedia('(prefers-color-scheme: dark)').matches
      }
      Dark.set(isDarkMode.value)
    }

    const toggleDarkMode = (value?: boolean) => {
      // If a value is provided, use it; otherwise toggle the current value
      isDarkMode.value = value !== undefined ? value : !isDarkMode.value
      Dark.set(isDarkMode.value)
      localStorage.setItem('darkMode', isDarkMode.value.toString())
    }

    const syncAppData = async (): Promise<void> => {
      const userStore = useUserStore()
      const settingsStore = useServerSettingsStore()

      const uid = userStore.user && userStore.user.id ? `/${userStore.user.id}` : ''
      try {
        const { data } = await axiosApi.post<SyncAppDataResponse>(`syncAppData${uid}`, {
          app_version: process.env.BUILD_DATE,
          url: `${capitalize(Platform.is.name)} ${Platform.is.versionNumber}`,

          datetime: dayJS().unix(),
        })
        serverTimeDiff = data.diff
        serverSettings = data.serverSettings ?? serverSettings
        dayJS.updateLocale('fr-ca', {
          weekStart: serverSettings.FIRST_DAY_OF_WEEK,
        })

        // Fetch settings after syncing app data
        await settingsStore.fetchSettings()
      } catch (err) {
        console.error('Error syncing app data:', err)
        // showErrorNotification(err);
      }
    }

    const updateVirtualDate = async (): Promise<void> => {
      const userStore = useUserStore()
      const time = userStore.user?.day_switch_time ?? '06:00:00'
      const date = dayJS().isBefore(`${dayJS().format('YYYY-MM-DD')} ${time}`) ? dayJS().subtract(1, 'day') : dayJS()
      virtualDate.value = date.format('YYYY-MM-DD')
    }

    const setConnectionStatus = async (): Promise<void> => {
      try {
        // Commented out until @capacitor/network is properly imported
        // connectionStatus.value = await Network.getStatus();
        connectionStatus.value = {
          connected: navigator.onLine,
          connectionType: navigator.onLine ? 'unknown' : 'none',
        }
      } catch (err) {
        console.error('Error getting connection status:', err)
      }
    }

    const getCurrentTime = async (): Promise<Dayjs> => {
      let currTime: Dayjs
      if (serverTimeDiff <= 0) {
        currTime = dayJS().subtract(Math.abs(serverTimeDiff), 's')
      } else {
        currTime = dayJS().add(serverTimeDiff, 's')
      }

      return currTime
    }

    const appVersion = `${process.env.BUILD_VERSION}.${process.env.BUILD_NUMBER}`

    const resetApp = async (): Promise<void> => {
      // empty local storage
      localStorage.clear()
      // delete all data from dexie
      const dbs = await window.indexedDB.databases()
      dbs.forEach((db) => {
        window.indexedDB.deleteDatabase(db.name)
      })
      // reset user store
    }
    return {
      resetApp,
      serverTimeDiff,
      virtualDate,
      connectionStatus,
      pageTitle,
      serverSettings,
      syncAppData,
      updateVirtualDate,
      setConnectionStatus,
      getCurrentTime,
      appVersion,
      isDarkMode,
      toggleDarkMode,
      initDarkMode,
    }
  },

  { persist: true },
)
